<template>
  <div class="exam-page">
    <div class="exam-dir">
      <NCard
        size="small"
        :segmented="{ content: 'hard' }"
        title="考试"
      >
        <NScrollbar style="height: calc(100vh - 108px)">
          <ul class="exam-dir-ul">
            <li
              v-for="item in examList"
              :key="item.paper_id"
              :class="{ current: currentPaperID === item.paper_id }"
              @click="handleExamSelect(item.paper_id)"
            >{{ item.title }}</li>
          </ul>
        </NScrollbar>
      </NCard>
    </div>
    <div class="exam-content">
      <NCard
        size="small"
        :segmented="{ content: 'hard' }"
        title="查看试卷"
      >
        <NScrollbar style="height: calc(100vh - 108px)">
          <ExamPreview
            v-if="currentPaperData"
            :paperData="currentPaperData"
          />
          <NEmpty
            v-else
            description="请选择考试"
            style="padding: 100px 0;"
          />
        </NScrollbar>
      </NCard>
    </div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NCard, NScrollbar, NEmpty } from 'naive-ui';

  import ExamPreview from '@/views/material/preview/components/ExamContent.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { getPaperDetail } from '@/api/paper.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false)

  const props = defineProps({
    examList: {
      type: Array,
      default: () => []
    }
  });

  const currentPaperID = ref('');
  const currentPaperData = ref(null);
  const pageIDDataMap = {};
  function handleExamSelect(paperID) {
    currentPaperID.value = paperID;
    if (pageIDDataMap[paperID]) {
      currentPaperData.value = pageIDDataMap[paperID];
    } else {
      loading.value = true;
      getPaperDetail({ 'Paper[id]': paperID }).then(({ code, data }) => {
        if (code === SUCCESS) {
          if (Array.isArray(data.paper_exercises)) {
            data.paper_exercises = data.paper_exercises.map(item => {
              item.options = item.ques_select;
              item.answer = item.ques_answer;
              item.analysis = item.ques_analys;
              item.fileList = item.upload_list;
              return item;
            });
          }
          pageIDDataMap[paperID] = data;
          currentPaperData.value = data;
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .exam-page {
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }

  .exam-dir, .exam-content {
    display: inline-block;
    vertical-align: top;
    height: 100%;
  }

  .exam-dir {
    width: 300px;
  }
  .exam-dir-ul {
    &, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    li {
      line-height: 32px;
      padding: 0 6px;
      border-radius: 3px;
      transition: background-color .3s;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &.current {
        background-color: @primary-color;
        color: #fff;
      }
    }
  }

  .exam-content {
    width: 889px;
    margin-left: 10px;
  }
</style>