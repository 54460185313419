<template>
  <div class="oper-btn-bar">
    <NDropdown
      placement="top-start"
      show-arrow
      :options="fileDropdownOptions"
      :render-label="fileDropdownLabelRender"
      @select="handleSelectFile"
    >
      <div class="oper-btn">
        <NIcon><ListAltOutlined /></NIcon>
        <h5>文件目录</h5>
      </div>
    </NDropdown>
    <div class="oper-btn" @click="toggleFullScreen">
      <template v-if="isFullScreen">
        <NIcon>
          <FullscreenExitOutlined />
        </NIcon>
        <h5>退出全屏</h5>
      </template>
      <template v-else>
        <NIcon>
          <FullscreenOutlined />
        </NIcon>
        <h5>全屏显示</h5>
      </template>
    </div>
    <div class="oper-btn"></div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, onBeforeUnmount, h } from 'vue';
  import { NDropdown, NIcon } from 'naive-ui';
  import { ListAltOutlined, FullscreenOutlined, FullscreenExitOutlined, AttachFileFilled } from '@vicons/material';

  import themeSettings from '@/settings/theme-settings.js'; 

  const emit = defineEmits(['full-screen-toggled', 'current-teaching-change']);
  const props = defineProps({
    teachingArr: {
      type: Array,
      default: () => []
    },
    currentTeachingIndex: {
      type: Number,
      default: -1
    }
  });

  const fileDropdownOptions = computed(() => {
    return [
      {
        type: 'group',
        label: `教案文件列表`,
        key: 'moduleTitle',
        children: props.teachingArr.map(({ name }, index) => ({
          label: name,
          key: String(index),
          index
        }))
      }
    ]
  });
  function fileDropdownLabelRender(option) {
    if (option.type === 'group') {
      return h('div', { style: 'padding: 0 10px 8px; font-size: 15px; font-weight: bold; color: #333; text-align: center;' }, option.label);
    } else {
      const labelEls = [
        h('div', { title: option.label, style: 'white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;' }, option.label)
      ];
      if (option.index === props.currentTeachingIndex) {
        labelEls.push(
          h('span', { style: `position: absolute; top: 0; right: 10px; color: ${themeSettings.primaryColor}` }, '（当前）')
        );
      }
      return h(
        'div',
        { style: 'width: 285px; padding: 0 65px 0 0;' },
        labelEls
      );
    }
  };
  function handleSelectFile(key, { index }) {
    emit('current-teaching-change', index);
  }

  const isFullScreen = ref(false);
  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen && document.exitFullscreen();
    }
    setTimeout(() => {
      emit('full-screen-toggled');
    }, 16);
  };
  function fullScreenToggleHandler() {
    isFullScreen.value = !!document.fullscreenElement;
  };
  onMounted(() => {
    fullScreenToggleHandler();
    document.addEventListener('fullscreenchange', fullScreenToggleHandler);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('fullscreenchange', fullScreenToggleHandler);
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .oper-btn-bar {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
  }

  .oper-btn {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    :deep(.n-icon) {
      font-size: 32px;
    }

    h5 {
      font-weight: normal;
      font-size: 12px;
      margin: -8px 0 0;
    }

    :deep(.n-icon), h5 {
      transition: color .3s;
    }

    &:hover {
      :deep(.n-icon), h5 {
        color: @primary-color;
      }
    }
  }
</style>