<template>
  <div class="task-dir">
    <NScrollbar style="height: calc(100vh - 35px);">
      <div class="intro" :class="{ 'with-cover': materialCover }">
        <img :src="materialCover" class="cover" />
        <div>
          <h1>{{ materialTitle }}</h1>
          <p>{{ materialIntro }}</p>
          <div class="skill-score-summary">
            <SkillScoreSummary
              :materialSkillArr="skillArr"
            />
          </div>
        </div>
      </div>
      <div v-if="examList.length > 0" class="exam">
        <NButton
          size="small"
          type="primary"
          @click.stop="emit('view-detail', { type: 'exam' })"
        >查看考试</NButton>
      </div>
      <div class="dir">
        <NCollapse v-model:expanded-names="lv1ExpandedNameArr">
          <NCollapseItem
            v-for="item in taskArrShow"
            :key="item.key"
            class="lv1-item"
            :title="item.title"
            :name="item.key"
          >
            <NCollapse v-model:expanded-names="item.lv2ExpandedNameArr">
              <NCollapseItem
                v-for="subItem in item.section_list"
                :key="subItem.key"
                class="lv2-item"
                :title="subItem.title"
                :name="subItem.key"
              >
                <template #header-extra>
                  <NButton
                    v-if="subItem.teachingArr.length > 0"
                    size="small"
                    type="primary"
                    @click.stop="emit('view-detail', { type: 'teaching', subTaskTitle: subItem.title, teachingArr: subItem.teachingArr })"
                  >查看教案</NButton>
                </template>
                <ul
                  v-if="subItem.knowledgeArr.length > 0"
                  class="knowledge-list"
                >
                  <li
                    v-for="item in subItem.knowledgeArr"
                    :key="item.id"
                    class="knowledge-item"
                  >
                    <span>{{ item.name }}</span>
                    <NButton
                      size="small"
                      type="primary"
                      class="detail-btn"
                      @click="emit('view-detail', { type: 'knowledge', knowledgeID: item.id, knowledgeName: item.name })"
                    >查看详情</NButton>
                  </li>
                </ul>
                <NEmpty
                  v-else
                  description="暂无内容"
                />
              </NCollapseItem>
            </NCollapse>
          </NCollapseItem>
        </NCollapse>
      </div>
    </NScrollbar>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { NScrollbar, NCollapse, NCollapseItem, NEmpty, NButton } from 'naive-ui';

  import SkillScoreSummary from '@/views/knowledge/index/knowledge-task/components/SkillScoreSummary.vue';

  const emit = defineEmits(['view-detail']);
  const props = defineProps({
    materialTitle: {
      type: String,
      default: ''
    },
    materialIntro: {
      type: String,
      default: ''
    },
    materialCover: {
      type: String,
      default: ''
    },
    skillArr: {
      type: Array,
      default: () => []
    },
    contentArr: {
      type: Array,
      default: () => []
    },
    examList: {
      type: Array,
      default: () => []
    },
  });

  const lv1ExpandedNameArr = ref([]);
  const taskArrShow = ref([]);

  watch(
    () => props.contentArr,
    arr => {
      lv1ExpandedNameArr.value = arr.map(({ key }) => key);
      taskArrShow.value = arr.map(item => ({
        ...item,
        lv2ExpandedNameArr: item.section_list.map(({ key }) => key)
      }));
    },
    { immediate: true }
  );
</script>

<style lang="less" scoped>
  .task-dir {
    display: inline-block;
    width: 1200px;
    margin: 15px 0;
    text-align: left;
    background-color: #fff;
  }

  .intro {
    position: relative;
    padding: 15px 120px 15px 20px;

    &.with-cover {
      min-height: 130px;
      padding-left: 240px;

      .cover {
        display: block;
      }
    }

    .cover {
      position: absolute;
      left: 20px;
      top: 15px;
      display: none;
      width: 200px;
      height: 130px;
    }

    h1 {
      margin: 5px 0 0;
      font-size: 18px;
    }

    p {
      margin: 10px 0;
      color: #999;
      word-break: break-all;
      word-break: break-word;
    }
  }

  .skill-score-summary {
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .exam {
    margin-bottom: 15px;
    padding-left: 20px;
  }

  .dir {
    padding: 0 20px 20px;
  }

  .lv1-item {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #efefef;
    border-radius: 6px;

    & > :deep(.n-collapse-item__header) {
      padding-top: 0;
    }
  }

  .lv2-item {
    margin-top: 0;
    border-top: none !important;
  }

  .knowledge-list, .knowledge-item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .knowledge-list {
    margin-left: 32px;
  }
  .knowledge-item {
    line-height: 36px;
    padding: 0 15px;
    transition: background-color .3s;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  .detail-btn {
    float: right;
    margin-top: 4px;
  }
</style>