<template>
  <div class="teaching-content-box" :style="{ minWidth: `${detailSize.w}px` }">
    <NCard
      class="teaching-content-card"
      size="small"
      :segmented="{ content: true }"
      :style="{ visibility: pageInited ? 'visible' : 'hidden', width: `${detailSize.w}px` }"
    >
      <template #header>
        <div style="margin: -4px -1px;">{{ subTaskTitle }}</div>
      </template>
      <template #default>
        <div class="content-box" :style="{ height: `${detailSize.h}px` }">
          <div v-if="currentTeachingData" style="position: relative; height: 100%;">
            <img
              style="width: 100%; height: 100%; object-fit: contain;"
              v-if="['.jpg', '.jpeg', '.png'].includes(currentTeachingData.ext)"
              :src="currentTeachingData.fileUrl"
            />
            <iframe
              v-else-if="'.pdf' === currentTeachingData.ext"
              :src="currentTeachingData.fileUrl"
              frameborder="0"
              style="width: 100%; height: 100%;"
            ></iframe>
            <VideoPlayer
              v-else-if="'.mp4' === currentTeachingData.ext"
              ref="videoPlayerRef"
              controlsShow
            />
          </div>
          <OperBtnBar
            :teachingArr="teachingArr"
            :currentTeachingIndex="currentTeachingIndex"
            @full-screen-toggled="updateDetailSize"
            @current-teaching-change="handleCurrentTeachingChange"
          />
        </div>
      </template>
    </NCard>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, onBeforeUnmount, nextTick } from 'vue';
  import { NCard } from 'naive-ui';

  import OperBtnBar from './OperBtnBar.vue';
  import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';

  const props = defineProps({
    subTaskTitle: {
      type: String,
      default: ''
    },
    teachingArr: {
      type: Array,
      default: () => []
    }
  });

  const pageInited = ref(true);
  const detailSize = reactive({
    w: 1920,
    h: 1080
  });
  function updateDetailSize() {
    const h = window.innerHeight - 15 * 2 - 58 - 66;
    const w = 1920 / 1080 * h;
    Object.assign(detailSize, {
      w,
      h
    });
  }
  onMounted(() => {
    updateDetailSize();
    window.addEventListener('resize', updateDetailSize);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateDetailSize);
  });

  const currentTeachingIndex = ref(0);
  const currentTeachingData = ref(props.teachingArr[0]);
  function checkCurrentIsVideoIfInit(data) {
    if (data && '.mp4' === data.ext) {
      nextTick(() => {
        videoPlayerRef.value.initVideo(data.videoId);
      });
    }
  }
  checkCurrentIsVideoIfInit(currentTeachingData.value);
  function handleCurrentTeachingChange(index) {
    currentTeachingIndex.value = index;
    const data = props.teachingArr[index];
    currentTeachingData.value = data || null;

    checkCurrentIsVideoIfInit(data);
  }

  const videoPlayerRef = ref(null);
</script>

<style lang="less" scoped>
  .teaching-content-box {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .teaching-content-card {
    box-sizing: content-box;
    text-align: left;

    :deep(.n-card-header) {
      height: 58px;
    }

    :deep(.n-card__content) {
      padding: 0;
    }
  }

  .content-box {
    position: relative;
    padding-bottom: 66px;

    :deep(.oper-btn-bar) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #efeff5;
    }
  }
</style>