<template>
  <div style="text-align: center;">
    <MaterialDir
      v-show="!currentDetailType"
      :materialTitle="materialTitle"
      :materialIntro="materialIntro"
      :materialCover="materialCover"
      :skillArr="skillArr"
      :contentArr="contentArr"
      :examList="examList"
      @view-detail="handleViewDetail"
    />
    <MaterialContent
      v-if="currentDetailType"
      :detailType="currentDetailType"
      :subTaskTitle="currentSubTaskTitle"
      :teachingArr="currentTeachingArr"
      :knowledgeID="currentKnowledgeID"
      :knowledgeName="currentKnowledgeName"
      :examList="examList"
      @back-to-dir="handleBackToDir"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import MaterialDir from './components/MaterialDir.vue';
  import MaterialContent from './components/MaterialContent.vue';

  defineProps({
    materialTitle: {
      type: String,
      default: ''
    },
    materialIntro: {
      type: String,
      default: ''
    },
    materialCover: {
      type: String,
      default: ''
    },
    skillArr: {
      type: Array,
      default: () => []
    },
    contentArr: {
      type: Array,
      default: () => []
    },
    examList: {
      type: Array,
      default: () => []
    }
  });

  const currentDetailType = ref('');
  const currentSubTaskTitle = ref('');
  const currentTeachingArr = ref([]);
  const currentKnowledgeID = ref('');
  const currentKnowledgeName = ref('');
  function handleViewDetail({ type, subTaskTitle, teachingArr, knowledgeID, knowledgeName }) {
    currentDetailType.value = type;
    switch (type) {
      case 'teaching':
        currentSubTaskTitle.value = subTaskTitle;
        currentTeachingArr.value = teachingArr;
        currentKnowledgeID.value = '';
        currentKnowledgeName.value = '';
        break;
      case 'knowledge':
        currentSubTaskTitle.value = '';
        currentTeachingArr.value = [];
        currentKnowledgeID.value = knowledgeID;
        currentKnowledgeName.value = knowledgeName;
        break;
      case 'exam':
        currentSubTaskTitle.value = '';
        currentTeachingArr.value = [];
        currentKnowledgeID.value = '';
        currentKnowledgeName.value = '';
        break;
    }
  }
  function handleBackToDir() {
    currentDetailType.value = '';
    currentSubTaskTitle.value = '';
    currentTeachingArr.value = [];
    currentKnowledgeID.value = '';
  }
</script>