<template>
  <div>
    <div class="back-box">
      <NButton
        type="primary"
        ghost
        circle
        @click="emit('back-to-dir')"
      >
        <template #icon>
          <NIcon><ArrowBackFilled /></NIcon>
        </template>
      </NButton>
    </div>
    <TeachingContent
      v-if="detailType === 'teaching'"
      :subTaskTitle="subTaskTitle"
      :teachingArr="teachingArr"
    />
    <TaskDetail
      v-else-if="detailType === 'knowledge'"
      ref="taskDetailRef"
      useFor="preview"
      :autoInit="false"
      :knowledgeName="knowledgeName"
      style="text-align: left;"
    />
    <ExamContent
      v-else-if="detailType === 'exam'"
      style="text-align: left;"
      :examList="examList"
    />
  </div>
</template>

<script setup>
  import { ref, watch, nextTick } from 'vue';
  import { NButton, NIcon } from 'naive-ui';
  import { ArrowBackFilled } from '@vicons/material';

  import TeachingContent from './TeachingContent/TeachingContent.vue';
  import TaskDetail from '@/views/knowledge/index/preview/components/detail.vue';
  import ExamContent from './ExamContent/ExamContent.vue';

  const emit = defineEmits(['back-to-dir']);
  const props = defineProps({
    detailType: {
      required: true,
      type: String
    },
    subTaskTitle: {
      type: String,
      default: ''
    },
    teachingArr: {
      type: Array,
      default: () => []
    },
    knowledgeID: {
      type: String,
      default: ''
    },
    knowledgeName: {
      type: String,
      default: ''
    },
    examList: {
      type: Array,
      default: () => []
    }
  });

  const taskDetailRef = ref(null);
  watch(
    () => props.knowledgeID,
    id => {
      if (props.detailType === 'knowledge') {
        nextTick(() => {
          taskDetailRef.value.manualInit(id);
        });
      }
    },
    { immediate: true }
  );
</script>

<style lang="less" scoped>
  .back-box {
    position: fixed;
    left: 15px;
    top: 15px;
    z-index: 1;
  }
</style>